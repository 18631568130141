import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";

function CollectionPage() {
  const { collectionName } = useParams();
  const { state } = useLocation();
  const [products, setProducts] = useState([]);
  const [sortOption, setSortOption] = useState("default");
  const db = getFirestore();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsSnapshot = await getDocs(collection(db, collectionName));
        const productsData = productsSnapshot.docs
          .filter((doc) => doc.id !== "heading")
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };

    fetchProducts();
  }, [db, collectionName]);

  const handleGoBack = () => {
    window.history.back();
  };

  const sortedData = [...products].sort((a, b) => {
    if (sortOption === "price-low") {
      return a.productPrice - b.productPrice;
    } else if (sortOption === "price-high") {
      return b.productPrice - a.productPrice;
    }
    return 0;
  });

  return (
    <>
      <NavBar />

      <div className="goBackBtn ms-5 mt-2" role="button" onClick={handleGoBack}>
        <i className="bi bi-arrow-left-short fs-3">Go Back</i>
      </div>

      <div className="container mt-3 text-center">
        <h1>{state?.heading || collectionName.replace("_", " ")}</h1>
        <div className="form-group text-start mt-4">
          <label htmlFor="sortOptions" className="form-label ms-2">
            Sort by:
          </label>
          <select
            id="sortOptions"
            className="form-select form-control bg-dark text-light border-0"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <option value="default">Default</option>
            <option value="price-low">Price, Low To High</option>
            <option value="price-high">Price, High To Low</option>
          </select>
        </div>
        <p className="text-light text-end">{sortedData.length} Products</p>
        <div className="row">
          {sortedData.map((product) => (
            <div className="col-6 col-md-4 col-lg-3 mb-4" key={product.id}>
              <div className="card border-0 p-2">
                <Link
                  to={`/product/${collectionName}/${product.id}`}
                  state={{ product, collectionName }}
                  className="link"
                >
                  <img
                    src={product.productImage}
                    className="card-img-top card-img-custom"
                    alt={product.productTitle}
                  />
                  <div className="card-body p-2">
                    <h5 className="card-title text-start">
                      {product.productTitle}
                    </h5>
                    <p className="card-text text-start">
                      Rs {product.productPrice} PKR
                      {product.oldPrice && (
                        <div className="card-text text-start old_price">
                          Rs {product.oldPrice} PKR
                        </div>
                      )}
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CollectionPage;
